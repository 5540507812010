body {
  --apidaeDarkBlue: #213162;
  --apidaeBlue: #168AC8;
  --apidaeRed: #F46754;
  --apidaeYellow: #FCEF8B;
  --apidaeGreen: #C7EAC4;
}

.in_progress {
  display: flex;
  align-items: center;
  justify-content: center;
}

.in_progress > div:first-child {
  margin-right: 12px;
}

button > span > svg, .simple-list > div:first-child svg {
  stroke-width: 0.2;
}

.simple-list td i, .simple-list td svg {
  background-color: transparent;
  fill: none;
}

.fill-none > button {
  border-color: transparent;
}

.fill-none > .simple-list > div {
  box-shadow: none;
}

.fill-none > .card-canvas {
  box-shadow: none;
}

.widget-color.widget-color-blue, .widget-color.widget-color-blue:hover {
  background-color: var(--colorBrandForeground1);
}

.widget-fill.widget-fill-solid, .widget-fill.widget-fill-solid:hover {
  background-color: var(--colorBrandForeground1);
  color: var(--colorNeutralForegroundInverted);
}

.theme-blue > button svg, .theme-blue > button i {
  stroke: var(--colorBrandForeground1);
  color: var(--colorBrandForeground1);
}

.theme-blue > button > span:last-child {
  background-color: var(--colorBrandForeground1);
  color: var(--colorNeutralForegroundInverted);
}

.theme-blue button:hover {
  border-color: var(--colorBrandForeground1);
}

.theme-blue .simple-list > div > div:first-child {
  background-color: var(--colorBrandForeground1);
  color: var(--colorNeutralForegroundInverted);
}

.theme-blue .simple-list > div > div:first-child svg, .theme-blue .simple-list > div > div:first-child i {
  color: var(--colorNeutralForegroundInverted);
}

.theme-blue .simple-list td i, .theme-blue .simple-list td svg {
  color: var(--colorBrandForeground1);
  stroke: var(--colorBrandForeground1);
}

.theme-blue.fill-solid > * {
  background-color: var(--colorBrandForeground1);
  color: var(--colorNeutralForegroundInverted);
}

.theme-blue.fill-solid > button svg, .theme-blue.fill-solid > button i {
  stroke: var(--colorNeutralForegroundInverted);
  color: var(--colorNeutralForegroundInverted);
}

.theme-blue.fill-outline > .card-canvas > :first-child {
  background-color: var(--colorBrandForeground1);
  color: var(--colorNeutralForegroundInverted);
}

.widget-color.widget-color-darkblue, .widget-color.widget-color-darkblue:hover {
  background-color: var(--apidaeDarkBlue);
}

.theme-darkblue > button svg, .theme-darkblue > button i {
  stroke: var(--apidaeDarkBlue);
  color: var(--apidaeDarkBlue);
}

.theme-darkblue > button > span:last-child {
  background-color: var(--apidaeDarkBlue);
  color: var(--colorNeutralForegroundInverted);
}

.theme-darkblue button:hover {
  border-color: var(--apidaeDarkBlue);
}

.theme-darkblue .simple-list > div > div:first-child {
  background-color: var(--apidaeDarkBlue);
  color: var(--colorNeutralForegroundInverted);
}

.theme-darkblue .simple-list > div > div:first-child svg, .theme-darkblue .simple-list > div > div:first-child i {
  color: var(--colorNeutralForegroundInverted);
}

.theme-darkblue.fill-solid > * {
  background-color: var(--apidaeDarkBlue);
  color: var(--colorNeutralForegroundInverted);
}

.theme-darkblue.fill-solid > button svg, .theme-darkblue.fill-solid > button i {
  stroke: var(--colorNeutralForegroundInverted);
  color: var(--colorNeutralForegroundInverted);
}

.theme-darkblue .simple-list td i, .theme-darkblue .simple-list td svg {
  color: var(--apidaeDarkBlue);
  stroke: var(--apidaeDarkBlue);
}

.theme-darkblue.fill-outline > .card-canvas > :first-child {
  background-color: var(--apidaeDarkBlue);
  color: var(--colorNeutralForegroundInverted);
}

.widget-color.widget-color-red, .widget-color.widget-color-red:hover {
  background-color: var(--apidaeRed);
}

.theme-red > button svg, .theme-red > button i {
  stroke: var(--apidaeRed);
  color: var(--apidaeRed);
}

.theme-red > button > span:last-child {
  background-color: var(--apidaeRed);
  color: var(--colorNeutralForegroundInverted);
}

.theme-red button:hover {
  border-color: var(--apidaeRed);
}

.theme-red .simple-list > div > div:first-child {
  background-color: var(--apidaeRed);
  color: var(--colorNeutralForegroundInverted);
}

.theme-red .simple-list > div > div:first-child svg, .theme-red .simple-list > div > div:first-child i {
  color: var(--colorNeutralForegroundInverted);
}

.theme-red .simple-list td i, .theme-red .simple-list td svg {
  color: var(--apidaeRed);
  stroke: var(--apidaeRed);
}

.theme-red.fill-solid > * {
  background-color: var(--apidaeRed);
  color: var(--colorNeutralForegroundInverted);
}

.theme-red.fill-solid > button svg, .theme-red.fill-solid > button i {
  stroke: var(--colorNeutralForegroundInverted);
  color: var(--colorNeutralForegroundInverted);
}

.theme-red.fill-outline > .card-canvas > :first-child {
  background-color: var(--apidaeRed);
  color: var(--colorNeutralForegroundInverted);
}

.widget-color.widget-color-yellow, .widget-color.widget-color-yellow:hover {
  background-color: var(--apidaeYellow);
}

.theme-yellow > button svg, .theme-yellow > button i {
  stroke: var(--apidaeYellow);
  color: var(--apidaeYellow);
}

.theme-yellow > button > span:last-child {
  background-color: var(--apidaeYellow);
  color: var(--colorNeutralForeground);
}

.theme-yellow button:hover {
  border-color: var(--apidaeYellow);
}

.theme-yellow .simple-list > div > div:first-child {
  background-color: var(--apidaeYellow);
  color: var(--colorNeutralForeground);
}

.theme-yellow .simple-list > div > div:first-child svg, .theme-yellow .simple-list > div > div:first-child i {
  color: var(--colorNeutralForeground);
}

.theme-yellow .simple-list td i, .theme-yellow .simple-list td svg {
  color: var(--apidaeYellow);
  stroke: var(--apidaeYellow);
}

.theme-yellow.fill-solid > * {
  background-color: var(--apidaeYellow);
  color: var(--colorNeutralForeground);
}

.theme-yellow.fill-solid > button svg, .theme-yellow.fill-solid > button i {
  stroke: var(--colorNeutralForeground);
  color: var(--colorNeutralForeground);
}

.theme-yellow.fill-outline > .card-canvas > :first-child {
  background-color: var(--apidaeYellow);
  color: var(--colorNeutralForeground);
}

.widget-color.widget-color-green, .widget-color.widget-color-green:hover {
  background-color: var(--apidaeGreen);
}

.theme-green > button svg, .theme-green > button i {
  stroke: var(--apidaeGreen);
  color: var(--apidaeGreen);
}

.theme-green > button > span:last-child {
  background-color: var(--apidaeGreen);
  color: var(--colorNeutralForeground);
}

.theme-green button:hover {
  border-color: var(--apidaeGreen);
}

.theme-green .simple-list > div > div:first-child {
  background-color: var(--apidaeGreen);
  color: var(--colorNeutralForeground);
}

.theme-green .simple-list > div > div:first-child svg, .theme-green .simple-list > div > div:first-child i {
  color: var(--colorNeutralForeground);
}

.theme-green .simple-list td i, .theme-green .simple-list td svg {
  color: var(--apidaeGreen);
  stroke: var(--apidaeGreen);
}

.theme-green.fill-solid > * {
  background-color: var(--apidaeGreen);
  color: var(--colorNeutralForeground);
}

.theme-green.fill-solid > button svg, .theme-green.fill-solid > button i {
  stroke: var(--colorNeutralForeground);
  color: var(--colorNeutralForeground);
}

.theme-green.fill-outline > .card-canvas > :first-child {
  background-color: var(--apidaeGreen);
  color: var(--colorNeutralForeground);
}
