@media screen and (min-width: 1025px) {
    .dashboard-grid.react-grid-layout {
        margin-top: 10px;
    }
}

@media screen and (max-width: 1024px) {
    .dashboard-grid.react-grid-layout {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        height: auto !important;
        margin-top: 20px;
    }

    .dashboard-grid.react-grid-layout > .react-grid-item {
        position: initial !important;
        width: initial !important;
        height: auto !important;
        min-height: 60px;
        transform: none !important;
    }
}

@media screen and (max-width: 640px) {
    .dashboard-grid.react-grid-layout {
        grid-template-columns: repeat(1, 1fr);
    }
}
