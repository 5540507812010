@font-face {
    font-family: 'poppins';
    src: url('./../assets/fonts/poppins-medium-webfont.woff2') format('woff2'),
    url('./../assets/fonts/poppins-medium-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'poppins';
    src: url('./../assets/fonts/poppins-semibold-webfont.woff2') format('woff2'),
    url('./../assets/fonts/poppins-semibold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'poppins';
    src: url('./../assets/fonts/poppins-light-webfont.woff2') format('woff2'),
    url('./../assets/fonts/poppins-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'poppins';
    src: url('./../assets/fonts/poppins-regular-webfont.woff2') format('woff2'),
    url('./../assets/fonts/poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
