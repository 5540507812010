body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.txtcenter {
  text-align: center;
}

.pas {
  padding: var(--spacingHorizontalS);
}

.pam {
  padding: var(--spacingHorizontalM);
}

.pal {
  padding: var(--spacingHorizontalL);
}

.paxl {
  padding: var(--spacingHorizontalXXL);
}

.mas {
  margin: var(--spacingHorizontalS);
}

.mam {
  margin: var(--spacingHorizontalM);
}

.mal {
  margin: var(--spacingHorizontalL);
}

.maxl {
  margin: var(--spacingHorizontalXXL);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: var(--fontWeightSemibold);
}

h1 {
  font-size: var(--fontSizeHero800);
  line-height: var(--lineHeightHero800);
}

h2 {
  font-size: var(--fontSizeHero700);
  line-height: var(--lineHeightHero700);
}

h3 {
  font-size: var(--fontSizeBase600);
  line-height: var(--lineHeightBase600);
}

h4 {
  font-size: var(--fontSizeBase500);
  line-height: var(--lineHeightBase500);
}

h5 {
  font-size: var(--fontSizeBase400);
  line-height: var(--lineHeightBase400);
}

h6 {
  font-size: var(--fontSizeBase300);
  line-height: var(--lineHeightBase300);
}

footer.page-footer {
  padding-bottom: 40px;
}
